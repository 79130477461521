var FontWhisperer;

module.exports = FontWhisperer = (function() {
  function FontWhisperer(context, fn) {
    this.context = context;
    this.fn = fn;
    this.listen();
  }

  FontWhisperer.prototype.listen = function() {
    return this.interval = window.setInterval((function(_this) {
      return function() {
        return _this.check();
      };
    })(this), 250);
  };

  FontWhisperer.prototype.check = function() {
    if (this.context.hasClass('wf-active')) {
      window.clearInterval(this.interval);
      return this.fn();
    }
  };

  return FontWhisperer;

})();
