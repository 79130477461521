var A24, SerializeObject;

A24 = require('data/constants');

SerializeObject = require('util/serialize-object');

module.exports = function() {
  var $header;
  $header = $('header');
  $('form.search-form-inputs').on('submit', function(e) {
    var $form, payload;
    $form = $(this);
    payload = SerializeObject($form);
    e.preventDefault();
    return window.location = '/search?q=' + payload.search;
  });
  return $('aside.search-wrapper').each(function(index, el) {
    var banner, close;
    banner = $(el);
    close = banner.find('button.close');
    return close.on('click', function(e) {
      return banner.removeClass('active');
    });
  });
};
