var $, Carousel;

$ = require('jquery');

Carousel = require('carousel/carousel');

module.exports = function(selector, context) {
  var slideshows;
  if (context == null) {
    context = null;
  }
  if (context) {
    slideshows = context.find(selector);
  } else {
    slideshows = $(selector);
  }
  return slideshows.each(function(index, el) {
    var root;
    root = $(el);
    return new Carousel(root, {
      auto: false,
      indicators: root.find('.indicators')
    });
  });
};
