var $, addErrors, removeErrors;

$ = require('jquery');

removeErrors = function(form) {
  var $errorsContainer;
  $errorsContainer = form.find('.errors');
  $errorsContainer.empty();
  return $errorsContainer.removeClass('with-errors');
};

addErrors = function(form, fields) {
  var $errorsContainer, error, errors, field, results;
  results = [];
  for (field in fields) {
    errors = fields[field];
    $errorsContainer = form.find("[data-field='" + field + "'] .errors");
    results.push((function() {
      var j, len, results1;
      results1 = [];
      for (j = 0, len = errors.length; j < len; j++) {
        error = errors[j];
        $errorsContainer.append($('<li/>').text(error));
        results1.push($errorsContainer.addClass('with-errors'));
      }
      return results1;
    })());
  }
  return results;
};

module.exports = function() {
  return $('form.custom').each(function(index, el) {
    var $form;
    $form = $(el);
    $form.attr('novalidate', true);
    return $form.on('submit', function(e) {
      e.preventDefault();
      return $.ajax({
        method: 'POST',
        data: new FormData(el),
        contentType: false,
        processData: false,
        beforeSend: function() {
          $form.find(':input').each(function(i, input) {
            return $(input).prop('disabled', true);
          });
          return $form.addClass('loading');
        },
        success: function(response) {
          removeErrors($form);
          if (response.success) {
            return window.location = response.returnUrl;
          } else {
            $form.find(':input').each(function(i, input) {
              return $(input).prop('disabled', false);
            });
            $form.removeClass('loading');
            return addErrors($form, response.errors);
          }
        }
      });
    });
  });
};
