var $, ImageFit, Sizer;

$ = require('jquery');

Sizer = require('util/sizer');

module.exports = ImageFit = (function() {
  function ImageFit(content, options) {
    this.content = content;
    if (options == null) {
      options = {};
    }
    this.options = $.extend(true, {
      mode: 'fill',
      anchorHorizontal: 'center',
      anchorVertical: 'center',
      min: 0,
      max: 2e308
    }, options);
    new Sizer({
      min: this.options.min,
      max: this.options.max,
      onActive: (function(_this) {
        return function(e) {
          return _this.resize(e);
        };
      })(this),
      onExit: (function(_this) {
        return function() {
          return _this.reset();
        };
      })(this)
    });
    this.resize();
    this.content.on('load', (function(_this) {
      return function(e) {
        return _this.resize(e);
      };
    })(this));
  }

  ImageFit.prototype.resize = function(e) {
    var context, env, img;
    context = this.content.parent();
    this.content.css({
      width: '',
      marginLeft: '',
      marginTop: ''
    });
    img = {
      width: this.content.outerWidth(),
      height: this.content.outerHeight(),
      ratio: this.content.outerWidth() / this.content.outerHeight()
    };
    env = {
      width: context.outerWidth(),
      height: context.outerHeight(),
      ratio: context.outerWidth() / context.outerHeight()
    };
    if (this.options.mode === 'fit') {
      this.fit(img, env);
    }
    if (this.options.mode === 'fill') {
      return this.fill(img, env);
    }
  };

  ImageFit.prototype.fit = function(img, env) {
    if (env.ratio > img.ratio) {
      return this.content.css({
        width: Math.ceil(img.ratio * env.height),
        marginLeft: Math.ceil((env.width - (img.ratio * env.height)) / 2),
        marginTop: 0
      });
    } else {
      return this.content.css({
        width: env.width,
        marginLeft: 0,
        marginTop: Math.floor((env.height - (env.width / img.ratio)) / 2)
      });
    }
  };

  ImageFit.prototype.fill = function(img, env) {
    if (env.ratio < img.ratio) {
      return this.content.css({
        width: Math.ceil(img.ratio * env.height),
        marginLeft: (function() {
          switch (this.options.anchorHorizontal) {
            case 'center':
              return Math.ceil((env.width - (img.ratio * env.height)) / 2);
            case 'left':
              return 0;
            case 'right':
              return Math.floor(env.width - (img.ratio * env.height));
            default:
              return 0;
          }
        }).call(this),
        marginTop: 0
      });
    } else {
      return this.content.css({
        width: env.width,
        marginLeft: 0,
        marginTop: (function() {
          switch (this.options.anchorVertical) {
            case 'center':
              return Math.floor((env.height - (env.width / img.ratio)) / 2);
            case 'bottom':
              return Math.floor(env.height - (env.width / img.ratio));
            default:
              return 0;
          }
        }).call(this)
      });
    }
  };

  ImageFit.prototype.reset = function() {
    return this.content.css({
      width: '',
      marginLeft: '',
      marginTop: ''
    });
  };

  return ImageFit;

})();
