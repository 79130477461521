var Loader, SerializeObject, requestDownload, requestToken;

Loader = require('util/loader');

SerializeObject = require('util/serialize-object');

requestToken = function($f) {
  var $messages, indicator, payload;
  indicator = null;
  $messages = $f.find('.messages');
  payload = SerializeObject($f);
  return $.ajax({
    method: 'POST',
    data: payload,
    beforeSend: function() {
      indicator = new Loader($f.find('.submit label'), 'inline');
      $f.find(':input').attr('disabled', true);
      $messages.empty();
      return $f.find('.error').each(function(index, el) {
        return $(el).empty();
      });
    },
    success: function(data) {
      var $err, $errContainer, $success, error, errors, key, ref, results;
      indicator.close();
      $f.find(':input').attr('disabled', false);
      if (data.success) {
        $success = $('<p/>').text(data.message);
        $messages.html($success);
        $messages.show();
        $(document.body).trigger('trackedAction', {
          trackedAction: 'printable_download',
          trackedActionParams: payload.printableTitle
        });
        return window.setTimeout(function() {
          return requestDownload(data);
        }, 350);
      } else {
        ref = data.errors;
        results = [];
        for (key in ref) {
          errors = ref[key];
          $errContainer = $f.find(":input[name='" + key + "']").siblings('.error');
          results.push((function() {
            var i, len, results1;
            results1 = [];
            for (i = 0, len = errors.length; i < len; i++) {
              error = errors[i];
              $err = $('<li/>').addClass('error').text(error);
              results1.push($errContainer.append($err));
            }
            return results1;
          })());
        }
        return results;
      }
    }
  });
};

requestDownload = function(data) {
  return window.location = data.downloadUrl;
};

module.exports = function() {
  var $htp, $htpButton, $htpContent;
  $('form.product-download').each(function(index, el) {
    var $form;
    $form = $(el);
    return $form.on('submit', function(e) {
      e.preventDefault();
      return requestToken($form);
    });
  });
  $htp = $('.how-to-print');
  $htpButton = $htp.find('button');
  $htpContent = $htp.find('.printing-options');
  return $htpButton.on('click', function(e) {
    e.preventDefault();
    $htpButton.toggleClass('open');
    return $htpContent.toggle();
  });
};
