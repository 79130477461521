var $, Debounce, Hammer, ImageCover, ImageLoad, LOTTIE, Modal, Sizer, StillsCollection, animationData;

$ = require('jquery');

LOTTIE = require('lottie-web');

animationData = require('../data/data.json');

Hammer = require('hammerjs');

Debounce = require('debounce');

Sizer = require('util/sizer');

Modal = require('util/modal');

ImageCover = require('util/image-cover');

ImageLoad = require('util/image-load');

StillsCollection = require('stills/collection');

module.exports = function() {
  var active_feature, body, burger, featureFigure, featureMedia, header, html, intervalID, intervalStarted, mobile_featured, resolve_featured, scrolled_featured, setActive, setupStackTile, setupVideoButton, show_featured, stack_films, stack_shows, startCarousel, stopCarousel, total_features, updateActiveTitle, video_buttons, windowWidth;
  html = $(document.documentElement);
  body = $(document.body);
  windowWidth = 0;
  header = $('header');
  burger = $('.burger');
  featureMedia = $('.featured .hero-media');
  featureFigure = $('.featured figure');
  mobile_featured = $('.featured-mobile');
  scrolled_featured = false;
  total_features = 0;
  active_feature = 1;
  intervalID = 0;
  intervalStarted = 0;
  stack_films = $('.stack-image.film');
  stack_shows = $('.stack-image.show');
  video_buttons = $('.play-video');
  setupVideoButton = function(f, el) {
    var button, image, video_animation;
    image = $(el);
    button = el.querySelector('.play-video');
    if (button) {
      video_animation = LOTTIE.loadAnimation({
        container: button,
        animationData: animationData,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        name: "Play Animation"
      });
      image.on('mouseenter', function(e) {
        return video_animation.play();
      });
      return image.on('mouseleave', function(e) {
        return video_animation.stop();
      });
    }
  };
  stack_films.each(function(f, el) {
    return setupVideoButton(f, el);
  });
  stack_shows.each(function(f, el) {
    return setupVideoButton(f, el);
  });
  show_featured = function() {
    if (mobile_featured.hasClass('hide')) {
      mobile_featured.removeClass('hide');
      scrolled_featured = false;
      html.addClass('featured-open');
      body.addClass('featured-open');
      window.scrollTo(0, 0);
    }
    if (header.hasClass('black')) {
      header.removeClass('black');
    }
    if (header.hasClass('hidden') || burger.hasClass('hidden')) {
      header.removeClass('hidden');
      return burger.removeClass('hidden');
    }
  };
  resolve_featured = function() {
    var pos;
    pos = window.scrollY || document.documentElement.scrollTop;
    if (scrolled_featured || mobile_featured.hasClass('hide')) {
      if (pos <= 0) {
        return show_featured();
      }
    }
  };
  new Sizer({
    onActive: function() {
      var height, pos;
      height = $('.featured .image').outerHeight();
      $('.featured').css('height', height);
      $('.titles-wrapper').addClass('loaded');
      pos = window.scrollY || document.documentElement.scrollTop;
      if (windowWidth !== $(window).width()) {
        if ($(window).width() <= 650) {
          stopCarousel();
          if (!scrolled_featured && pos === 0) {
            html.addClass('featured-open');
            body.addClass('featured-open');
            scrolled_featured = false;
          }
          if (pos > 0) {
            scrolled_featured = true;
            html.removeClass('featured-open');
            body.removeClass('featured-open');
            $('.featured-mobile').addClass('hide');
            header.addClass('black');
            window.scrollTo(0, 1);
          }
          $(window).on('scroll', resolve_featured);
          windowWidth = $(window).width();
        }
      }
      if ($(window).width() > 650) {
        startCarousel();
        html.removeClass('featured-open');
        body.removeClass('featured-open');
        if (pos < $('.hero-media').outerHeight() && header.hasClass('sticky')) {
          return header.removeClass('sticky');
        }
      }
    }
  });
  $('.featured-mobile').each(function(f, el) {
    var feature, indicator_active, indicator_line, indicator_total;
    feature = $(el);
    indicator_active = feature.find('.indicator-active');
    indicator_total = feature.find('.indicator-total');
    indicator_line = feature.find('.indicator-line');
    total_features = $('.featured-item.mobile').length;
    indicator_total.html(total_features);
    return $('.featured-item.mobile').each(function(f, el) {
      var Swipe, cover, item, mc, next, poster, previous, scrollUpdate;
      item = $(el);
      poster = item.find('img');
      cover = new ImageCover(poster);
      new ImageLoad(poster, function(img) {
        return cover.resize();
      });
      scrollUpdate = function(e) {
        var delta;
        delta = e.originalEvent.wheelDelta;
        if (delta < 0) {
          return next();
        } else if (delta > 0) {
          return previous();
        }
      };
      item.bind('mousewheel', Debounce(scrollUpdate, 100, true));
      mc = new Hammer.Manager(item[0]);
      Swipe = new Hammer.Swipe({
        threshold: 5
      });
      mc.add(Swipe);
      next = function() {
        if (item.next().hasClass('featured-item') && active_feature < total_features) {
          item.next().addClass('active');
          item.removeClass('active');
          active_feature++;
          indicator_active.html(active_feature);
          indicator_line.css('height', (((total_features - 1) * (100 / total_features)) - ((active_feature - 1) * (100 / total_features))) + 'px');
          if (active_feature === total_features) {
            indicator_total.html('');
            indicator_total.addClass('arrow');
          }
        } else if (active_feature === total_features) {
          scrolled_featured = true;
          html.removeClass('featured-open');
          body.removeClass('featured-open');
          feature.addClass('hide');
          header.addClass('black');
          return window.scrollTo(0, 1);
        }
      };
      previous = function() {
        if (item.prev().hasClass('featured-item') && active_feature > 0) {
          item.prev().addClass('active');
          item.removeClass('active');
          if (active_feature === total_features) {
            indicator_total.html(total_features);
            indicator_total.removeClass('arrow');
          }
          active_feature--;
          indicator_active.html(active_feature);
          return indicator_line.css('height', (((total_features - 1) * (100 / total_features)) - ((active_feature - 1) * (100 / total_features))) + 'px');
        }
      };
      mc.on('swipeup', next);
      return mc.on('swipedown', previous);
    });
  });
  $('.featured .featured-item').each(function(f, el) {
    var cover, feature, play_button, poster;
    feature = $(el);
    poster = feature.find('img');
    cover = new ImageCover(poster);
    new ImageLoad(poster, function(img) {
      return cover.resize();
    });
    play_button = feature.find('button.play-video');
    play_button.on('click', function(e) {
      var frame, id, video;
      id = feature.data('video-id');
      frame = $('<iframe />').attr('src', "https://youtube.com/embed/" + id + "?autoplay=1");
      video = $('<div />').addClass('responsive-video');
      return new Modal(video.append(frame));
    });
    new Sizer({
      min: 769,
      once: true,
      onEnter: function() {
        return feature.find('.stills.hero').each(function(c, el) {
          return new StillsCollection($(el), feature, f * 500);
        });
      }
    });
    play_button.on('mouseenter', function(e) {
      return feature.addClass('may-play');
    });
    return play_button.on('mouseleave', function(e) {
      return feature.removeClass('may-play');
    });
  });
  setupStackTile = function(f, el) {
    var cover, play_button, poster, stack, stackImage, stackImageArrWrapper, stills;
    stack = $(el);
    poster = stack.find('img');
    stills = stack.find('.stills');
    stackImage = stack.find('.stack-image');
    cover = new ImageCover(poster, {
      anchorHorizontal: 'left'
    });
    new ImageLoad(poster, function(img) {
      return cover.resize();
    });
    play_button = stack.find('button.play-video');
    if (play_button.length > 0) {
      play_button.css('pointer-events', 'none');
      stackImage.on('click', function(e) {
        var frame, id, video;
        id = stack.data('video-id');
        frame = $('<iframe />').attr('src', "https://youtube.com/embed/" + id + "?autoplay=1");
        video = $('<div />').addClass('responsive-video');
        return new Modal(video.append(frame));
      });
    }
    new Sizer({
      min: 769,
      once: true,
      onEnter: function() {
        return stack.find('.stills.stack').each(function(c, el) {
          return new StillsCollection($(el), stack, f * 500);
        });
      }
    });
    play_button.on('mouseenter', function(e) {
      return stack.addClass('may-play');
    });
    play_button.on('mouseleave', function(e) {
      return stack.removeClass('may-play');
    });
    stackImageArrWrapper = stack.find('.stack-image .stills.stack');
    stackImage.on('mouseenter', function(e) {
      return stackImageArrWrapper.addClass('playing');
    });
    return stackImage.on('mouseleave', function(e) {
      return stackImageArrWrapper.removeClass('playing');
    });
  };
  $('.stack-item.film').each(function(f, el) {
    return setupStackTile(f, el);
  });
  $('.stack-item.show').each(function(f, el) {
    return setupStackTile(f, el);
  });
  updateActiveTitle = function(featureTitle, carousel) {
    var gif, heroGif, heroImage, heroImageArr, heroImageArrWrapper, heroItem, loadingColor, num, options, still;
    gif = featureTitle.attr('data-gif');
    still = featureTitle.attr('data-image');
    heroImageArrWrapper = $('.featured .image .stills.hero');
    heroImageArr = $('.featured .image .still');
    $('.featured .featured-item-title').each(function(f, el) {
      if ($(el).hasClass('active')) {
        return $(el).removeClass('active');
      }
    });
    featureTitle.addClass('active');
    heroItem = $('.featured .featured-item');
    heroImage = $('.featured .image img');
    num = Math.floor(Math.random() * (4 - 1 + 1));
    options = ['cyan', 'yellow', 'magenta', 'cymg'];
    loadingColor = 'loading ' + options[num];
    featureFigure.addClass('loading');
    featureMedia.addClass(loadingColor);
    if (carousel === true) {
      stopCarousel();
      window.setTimeout(function() {
        return heroImage.attr('src', still);
      }, 200);
      return heroImage.on('load', function(e) {
        return window.setTimeout(function() {
          featureFigure.removeClass('loading');
          featureMedia.removeClass(loadingColor);
          return startCarousel();
        }, 500);
      });
    } else {
      stopCarousel();
      heroImage.attr('src', still);
      if (gif) {
        heroGif = $('.featured .still.hero img');
        heroImageArr.attr('data-gif', gif);
        heroGif.attr('src', gif);
        return heroGif.on('load', function(e) {
          return window.setTimeout(function() {
            featureFigure.removeClass('loading');
            return featureMedia.removeClass(loadingColor);
          }, 300);
        });
      }
    }
  };
  stopCarousel = function() {
    if (intervalID !== 0) {
      clearInterval(intervalID);
      intervalID = 0;
      return intervalStarted = 0;
    }
  };
  startCarousel = function() {
    if (intervalStarted === 0) {
      intervalID = setInterval(function() {
        return setActive(true);
      }, 4000);
      return intervalStarted = 1;
    }
  };
  setActive = function(carousel) {
    var activeTitle, featureTitles, nextFeature;
    featureTitles = $('.featured-item-title');
    activeTitle = $('.featured-item-title.active');
    if (activeTitle.next().hasClass('featured-item-title')) {
      nextFeature = activeTitle.next();
    } else {
      nextFeature = featureTitles.first();
    }
    return updateActiveTitle(nextFeature, carousel);
  };
  return $('.featured .featured-item-title').each(function(f, el) {
    var featureTitle, heroImageArrWrapper;
    featureTitle = $(el);
    heroImageArrWrapper = $('.image .stills.hero');
    if (f === 0) {
      featureTitle.addClass('active');
    }
    new Sizer({
      min: 1024,
      onEnter: function() {
        return featureTitle.on('mouseenter', function(e) {
          window.setTimeout(function() {
            return heroImageArrWrapper.addClass('playing');
          }, 200);
          updateActiveTitle($(el), false);
          return stopCarousel();
        });
      }
    });
    return featureTitle.on('mouseleave', function(e) {
      return heroImageArrWrapper.removeClass('playing');
    });
  });
};
