var $, Sizer;

$ = require('jquery');

module.exports = Sizer = (function() {
  function Sizer(options) {
    if (options == null) {
      options = {};
    }
    this.screen = $(window);
    this.active = null;
    this.count = 0;
    this.options = $.extend({
      min: 0,
      max: 2e308,
      once: false,
      onEnter: function(e) {},
      onExit: function(e) {},
      onActive: function(e) {}
    }, options);
    this.screen.on('resize', (function(_this) {
      return function(e) {
        return _this.resize(e);
      };
    })(this));
  }

  Sizer.prototype.resize = function(e) {
    var current, ref;
    current = (window.innerWidth >= this.options.min) && (window.innerWidth < this.options.max);
    if (((ref = this.active) === false || ref === null) && current === true) {
      this.options.onEnter(e);
    }
    if (this.active === true && current === false) {
      this.options.onExit(e);
    }
    if (this.active === null && current === false) {
      this.options.onExit(e);
    }
    this.active = current;
    if (this.active) {
      ++this.count;
      if (!(this.options.once && this.count > 1)) {
        return this.options.onActive(e);
      }
    }
  };

  return Sizer;

})();
