var $, Modal, Sizer;

$ = require('jquery');

Sizer = require('util/sizer');

module.exports = Modal = (function() {
  function Modal(content, name, callbacks) {
    this.content = content;
    this.name = name != null ? name : 'unnamed-modal';
    this.events = $.extend({
      onAfterOpen: function(modal) {},
      onBeforeDestroy: function(modal) {}
    }, callbacks);
    this.body = $(document.documentElement);
    this.create();
    this.open();
  }

  Modal.prototype.create = function() {
    var close, container, controls, modal_content, wrap;
    container = $('<aside/>').addClass(['modal', this.name].join(' '));
    wrap = $('<div/>').addClass('modal-wrap');
    close = $('<button/>').addClass('close').attr({
      title: 'Close'
    }).on('click', (function(_this) {
      return function() {
        return _this.close();
      };
    })(this));
    controls = $('<div/>').addClass('modal-controls').append(close);
    modal_content = $('<div/>').addClass('modal-content').append(this.content);
    wrap.append(modal_content);
    wrap.prepend(controls);
    container.append(wrap);
    this.el = container.appendTo(document.body);
    return this.el.on('click', (function(_this) {
      return function(e) {
        if (_this.el.is(e.target)) {
          e.stopPropagation();
          return _this.close();
        }
      };
    })(this));
  };

  Modal.prototype.open = function() {
    this.el.css('display', 'block');
    this.body.css('overflow', 'hidden');
    return this.events.onAfterOpen(this);
  };

  Modal.prototype.close = function() {
    this.el.css('display', 'none');
    this.body.css('overflow', 'scroll');
    return this.destroy();
  };

  Modal.prototype.destroy = function() {
    this.events.onBeforeDestroy(this);
    return this.el.remove();
  };

  return Modal;

})();
