var $, ImageCover, Modal;

$ = require('jquery');

Modal = require('util/modal');

ImageCover = require('util/image-cover');

module.exports = function(selector, context) {
  var videos;
  if (context == null) {
    context = null;
  }
  if (context) {
    videos = context.find(selector);
  } else {
    videos = $(selector);
  }
  return videos.each(function(index, video) {
    var $video;
    $video = $(video);
    return $video.on('click', function(e) {
      var container, frame, id, url;
      id = $video.data('video-id');
      url = (function() {
        switch ($video.data('video-provider')) {
          case 'youtube':
            return "https://youtube.com/embed/" + id + "?autoplay=1";
          case 'vimeo':
            return "https://player.vimeo.com/video/" + id + "?autoplay=1";
        }
      })();
      frame = $('<iframe/>').attr('src', url);
      container = $('<div />').addClass('responsive-video');
      return new Modal(container.append(frame));
    });
  });
};
