var ImageCover, ImageLoad, Still;

window.$ = require('jquery');

ImageCover = require('util/image-cover');

ImageLoad = require('util/image-load');

module.exports = Still = (function() {
  function Still(el, collection) {
    this.el = el;
    this.collection = collection;
    this.image = $('<img/>');
    this.ready = false;
    this.transition = null;
    this.el.append(this.image);
    this.filler = new ImageCover(this.image);
  }

  Still.prototype.load = function() {
    this.image.attr({
      src: this.el.data('image')
    });
    return new ImageLoad(this.image, (function(_this) {
      return function(img) {
        return _this.onAfterLoad();
      };
    })(this));
  };

  Still.prototype.onAfterLoad = function() {
    this.el.addClass('loaded');
    this.resize();
    return this.ready = true;
  };

  Still.prototype.activate = function() {
    window.clearTimeout(this.transition);
    if (!this.ready) {
      this.load();
    }
    this.el.appendTo(this.collection.context);
    this.el.addClass('active');
    this.el.addClass('visible');
    return this.el.fadeIn({
      duration: 500,
      queue: false,
      start: (function(_this) {
        return function() {
          return _this.resize();
        };
      })(this)
    });
  };

  Still.prototype.deactivate = function() {
    this.el.removeClass('active');
    return this.transition = window.setTimeout((function(_this) {
      return function() {
        _this.el.hide();
        return _this.el.removeClass('visible');
      };
    })(this), 500);
  };

  Still.prototype.resize = function() {
    return this.filler.resize();
  };

  return Still;

})();
