var Sizer;

Sizer = require('util/sizer');

module.exports = function() {
  var footer, footer_is_positioned;
  footer = $('footer');
  footer_is_positioned = false;
  return new Sizer({
    onExit: function() {
      return footer.css({
        marginTop: 0
      });
    },
    onActive: function() {
      if (!footer_is_positioned) {
        footer_is_positioned = true;
        footer.addClass('ready');
      }
      footer.css({
        marginTop: 0
      });
      if (document.body.offsetHeight < window.innerHeight) {
        return footer.css({
          marginTop: window.innerHeight - document.body.offsetHeight
        });
      }
    }
  });
};
