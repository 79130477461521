var $, ImageCover, ImageLoad;

$ = require('jquery');

ImageCover = require('util/image-cover');

ImageLoad = require('util/image-load');

module.exports = function() {
  return $('.header-image img').each(function(index, img) {
    var $img, cover;
    $img = $(img);
    cover = new ImageCover($img, {
      min: 300
    });
    return new ImageLoad($img, function(i) {
      return cover.resize();
    });
  });
};
