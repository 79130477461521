var $, Meta;

$ = require('jquery');

Meta = require('util/meta');

module.exports = function() {
  var $body;
  $body = $(document.body);
  $('.csrf-placeholder').each(function(index, el) {
    var input;
    input = $(el);
    return input.val(Meta('csrf'));
  });
  return $(document.body).on('trackedAction', function(e, data) {
    if ($body.hasClass('is-production')) {
      return operam('track', data.trackedAction, data.trackedActionParams);
    }
  });
};
