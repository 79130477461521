var MapNumber;

MapNumber = require('util/number-map');

module.exports = function() {
  var animated_props, defs, gradient, logo, logo_state, path, tween_logo_gradient;
  logo = document.querySelector('.logo-animated svg');
  defs = logo.querySelector('defs');
  gradient = logo.querySelector('linearGradient');
  path = logo.querySelector('path');
  logo_state = {
    animating: false,
    animation_interval: null,
    animation_began: null
  };
  animated_props = {
    x1: {
      from: 0,
      to: -700
    },
    x2: {
      from: 800,
      to: 100
    }
  };
  tween_logo_gradient = function(timestamp) {
    var cursor, prop, val;
    if (!timestamp) {
      timestamp = 0;
    }
    if (!logo_state.animation_began) {
      logo_state.animation_began = timestamp;
    }
    path.style.fill = '';
    defs.removeChild(gradient);
    cursor = (timestamp - logo_state.animation_began) % 8000;
    for (prop in animated_props) {
      val = animated_props[prop];
      gradient.setAttribute(prop, (MapNumber(cursor, 0, 8000, val.from, val.to)) + "%");
    }
    defs.appendChild(gradient);
    path.style.fill = 'url(#rainbow)';
    return logo_state.animation = window.requestAnimationFrame(function(t) {
      return tween_logo_gradient(t);
    });
  };
  logo.addEventListener('mouseenter', function() {
    logo_state.animating = true;
    return tween_logo_gradient();
  });
  return logo.addEventListener('mouseleave', function() {
    path.style.fill = '';
    logo_state.animating = false;
    logo_state.animation_began = null;
    return window.cancelAnimationFrame(logo_state.animation);
  });
};
