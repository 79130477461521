var $, FontWhisperer, ImageCover, ImageLoad, lazy_images;

$ = require('jquery');

ImageLoad = require('util/image-load');

ImageCover = require('util/image-cover');

FontWhisperer = require('util/font-whisperer');

lazy_images = require('init/tasks/lazy-images');

module.exports = function() {
  var $w, button, expand, filters_are_visible, filters_button, filters_content, hide_filters, hide_list, list, list_expandable, list_reveal, list_total, more, relatedStoryTitles, resizeFeatureTiles, showMore, show_filters;
  $w = $(window);
  filters_are_visible = false;
  filters_button = $('a.active-filter');
  filters_content = $('.controls');
  show_filters = function() {
    filters_content.addClass('active');
    filters_button.addClass('active');
    if (window.history.pushState) {
      window.history.replaceState(null, null, '#filter');
    } else {
      window.location.hash = '#filter';
    }
    return filters_are_visible = true;
  };
  hide_filters = function() {
    filters_content.removeClass('active');
    filters_button.removeClass('active');
    if (window.history.pushState) {
      window.history.replaceState(null, null, window.location.pathname);
    } else {
      window.location.hash = '#filter';
    }
    return filters_are_visible = false;
  };
  if (window.location.hash === '#filter') {
    show_filters();
  }
  filters_button.on('click', function(e) {
    e.preventDefault();
    if (filters_are_visible) {
      return hide_filters();
    } else {
      return show_filters();
    }
  });
  $('article.archive-preview img').each(function(index, img) {
    return new ImageLoad($(img), function(i) {
      return i.parent().addClass('ready');
    });
  });
  relatedStoryTitles = $('.secondary-features .feature-titling');
  resizeFeatureTiles = function(e) {
    var tallestTitle;
    tallestTitle = 0;
    relatedStoryTitles.css('height', '');
    relatedStoryTitles.each(function(index, title) {
      var $title, height;
      $title = $(title);
      height = $title.outerHeight();
      if (height > tallestTitle) {
        return tallestTitle = height;
      }
    });
    return relatedStoryTitles.css('height', tallestTitle);
  };
  $w.on('resize', resizeFeatureTiles);
  new FontWhisperer($('html'), function() {
    return $w.trigger('resize');
  });
  $('main.notes').each(function(index, el) {
    var container, controls, filter, value;
    container = $(el);
    controls = container.find('.controls');
    filter = container.find('.active-filter');
    value = null;
    if (window.location.href.indexOf('?') >= 0) {
      value = window.location.href.split('?')[1].slice(2);
    }
    if (value === 'Article') {
      value = 'Articles';
    } else if (value === 'Zine') {
      value = 'Zines';
    }
    if (value && value.length > 1) {
      filter.html(value);
    }
    return container.find('input[name=filter]').on('change', function() {
      var select;
      select = $(this);
      return window.location = '/notes?f=' + select.val();
    });
  });
  list_expandable = true;
  list_reveal = 5;
  list_total = 0;
  list = $('.archive-preview');
  list_total = list.length;
  more = $('.show-more-wrapper');
  button = $('.show-more-wrapper');
  showMore = $('.show-more-button');
  if (list_expandable && list_reveal < list_total) {
    more.css('display', 'flex');
  }
  expand = function() {
    list_reveal += 5;
    list.each((function(_this) {
      return function(index, el) {
        var item;
        item = $(el);
        if (index > list_reveal) {
          return item.css('display', 'none');
        } else {
          return item.css('display', 'flex');
        }
      };
    })(this));
    if (list_reveal >= list_total) {
      list_expandable = false;
      return showMore.css('display', 'none');
    }
  };
  hide_list = function() {
    list = $('.archive-preview');
    showMore = $('.show-more-button');
    more = $('.show-more-wrapper');
    showMore.on('click', (function(_this) {
      return function() {
        if (list_expandable) {
          return expand();
        }
      };
    })(this));
    if (list_expandable && list_reveal < list_total) {
      more.css('display', 'block');
    } else {
      more.css('display', 'none');
    }
    return list.each((function(_this) {
      return function(index, el) {
        var item;
        item = $(el);
        if (index > list_reveal) {
          return item.css('display', 'none');
        }
      };
    })(this));
  };
  return hide_list();
};
