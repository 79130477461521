var $, Scroller, Sizer;

$ = require('jquery');

Sizer = require('util/sizer');

Scroller = require('util/scroller');

module.exports = function() {
  var $body, $burger, $main_content, $search_btn, $search_input, $search_wrapper, $sticky_nav, hero, hero_height, hide, resolve_stickiness, reveal, stick, sticky_nav_off, sticky_nav_offset, sticky_nav_shim, sticky_nav_visible, unstick;
  $sticky_nav = $('header');
  $body = $('body');
  $burger = $('.burger');
  $main_content = $('main');
  $search_btn = $('.item.search');
  $search_wrapper = $('aside.search-wrapper');
  $search_input = $search_wrapper.find('input[name=search]');
  hero = false;
  hero_height = 0;
  sticky_nav_visible = false;
  sticky_nav_offset = 0;
  sticky_nav_shim = 0;
  sticky_nav_off = false;
  sticky_nav_shim = $sticky_nav.outerHeight();
  if (!$body.hasClass('search')) {
    $search_btn.on('click', function(e) {
      $search_wrapper.addClass('active');
      return $search_input.focus();
    });
  } else if ($body.hasClass('search')) {
    $search_btn.on('click', function() {
      if ($(window).width() > 650) {
        return $('.search-bar').find('input[name=search]').focus();
      } else {
        $search_wrapper.addClass('active');
        return $search_input.focus();
      }
    });
  }
  $sticky_nav.on('sticky.requireUpdate', function(e) {
    return sticky_nav_offset = $sticky_nav.offset().top;
  });
  if ($sticky_nav.hasClass('hero')) {
    hero = true;
  }
  stick = function() {
    sticky_nav_shim = $sticky_nav.outerHeight();
    sticky_nav_offset = $sticky_nav.offset().top - $sticky_nav.outerHeight() / 1.8;
    $sticky_nav.addClass('sticky');
    return sticky_nav_visible = true;
  };
  unstick = function() {
    $sticky_nav.removeClass('sticky');
    return sticky_nav_visible = false;
  };
  resolve_stickiness = function() {
    var pos;
    pos = window.scrollY || document.documentElement.scrollTop;
    if (hero) {
      if (pos > $('.hero-media').outerHeight() - 350 && !sticky_nav_visible) {
        stick();
      }
      if (pos < $('.hero-media').outerHeight() - 350 && sticky_nav_visible) {
        return unstick();
      }
    }
  };
  new Sizer({
    onEnter: function() {
      resolve_stickiness();
      return $(window).on('scroll.stickyNav', resolve_stickiness);
    },
    onExit: function() {
      unstick();
      return $(window).off('scroll.stickyNav');
    }
  });
  hide = function() {
    var pos;
    pos = window.scrollY || document.documentElement.scrollTop;
    if (pos > 76) {
      $sticky_nav.addClass('hidden');
      $burger.addClass('hidden');
      return sticky_nav_off = true;
    }
  };
  reveal = function() {
    $sticky_nav.removeClass('hidden');
    $burger.removeClass('hidden');
    return sticky_nav_off = false;
  };
  return new Scroller({
    onDown: function() {
      return hide();
    },
    onUp: function() {
      return reveal();
    }
  });
};
