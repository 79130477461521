var A24, Cookie, SerializeObject, Subscribe;

Cookie = require('cookies-js');

Subscribe = require('klaviyo-subscribe');

A24 = require('data/constants');

SerializeObject = require('util/serialize-object');

module.exports = function() {
  var $header, dismiss_banner, validate_email;
  $header = $('header');
  dismiss_banner = function(time) {
    return Cookie.set('newsletter-signup-dismissed', true, {
      expires: time
    });
  };
  validate_email = function(email) {
    var re;
    re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  $('form.newsletter-signup').on('submit', function(e) {
    var $form, messages, payload;
    $form = $(this);
    payload = SerializeObject($form);
    console.log('payload:', payload);
    messages = $form.find('.messages');
    e.preventDefault();
    return Subscribe.subscribe(payload.g, payload.email, {
      $source: payload.signupLocation
    }).then(function(response) {
      console.log(response);
      if (validate_email(payload.email) === true && response.data.is_subscribed === false) {
        messages.removeClass('error');
        $form.addClass('success');
        messages.addClass('success');
        messages.show();
        dismiss_banner(A24.FUTURE);
        return $(document.body).trigger('trackedAction', {
          trackedAction: 'newsletter_subscribe',
          trackedActionParams: payload.signupLocation
        });
      } else {
        messages.addClass('error');
        return messages.show();
      }
    })["catch"](function(error) {
      return console.log(error);
    });
  });
  return $('aside.newsletter-banner').each(function(index, el) {
    var banner, close;
    banner = $(el);
    close = banner.find('button.close-newsletter');
    if (!Cookie('visited')) {
      Cookie.set('visited', true, {
        expires: A24.ONE_WEEK
      });
      banner.addClass('active');
    }
    return close.on('click', function(e) {
      banner.removeClass('active');
      if (!Cookie.get('newsletter-signup-dismissed')) {
        return dismiss_banner(A24.FUTURE);
      }
    });
  });
};
