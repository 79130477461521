var $, Carousel, ImageCover, ImageLoad, Slide;

$ = require('jquery');

Carousel = require('carousel/carousel');

Slide = require('carousel/slide');

ImageCover = require('util/image-cover');

ImageLoad = require('util/image-load');

module.exports = function() {
  $('section.header .slideshow').each(function(index, el) {
    var root;
    root = $(el);
    return new Carousel(root, {
      auto: false,
      indicators: root.find('.indicators'),
      slideType: Slide
    });
  });
  return $('section.explore .feature-tile').each(function(index, feature) {
    var $feature, $img, cover;
    $feature = $(feature);
    $img = $feature.find('img');
    cover = new ImageCover($img);
    return new ImageLoad($img, function(i) {
      cover.resize();
      return $feature.addClass('ready');
    });
  });
};
