var $, Meta;

$ = require('jquery');

Meta = require('util/meta');

module.exports = function() {
  Meta('key-maps');
  return google.load('maps', 3, {
    other_params: "key=" + (Meta('key-maps')),
    callback: function() {
      return $('section.locations .map').each(function(i, m) {
        var canvas, center, container, map;
        container = $(m);
        canvas = container.find('.map-canvas');
        center = {
          lat: parseFloat(container.data('lat')),
          lng: parseFloat(container.data('lng'))
        };
        map = new google.maps.Map(canvas.get(0), {
          center: center,
          zoom: container.data('zoom'),
          disableDefaultUI: true,
          scrollwheel: false,
          styles: require('data/map-styles')
        });
        return new google.maps.Marker({
          map: map,
          position: center,
          icon: {
            url: '/assets/images/icons/map-pin.png',
            size: new google.maps.Size(80, 100),
            scaledSize: new google.maps.Size(40, 50),
            anchor: new google.maps.Point(20, 50)
          }
        });
      });
    }
  });
};
