var $, Balancer, ImageCover, ImageLoad, LOTTIE, Modal, Sizer, StillsCollection, animationData, slideshows;

$ = require('jquery');

LOTTIE = require('lottie-web');

animationData = require('../data/data.json');

slideshows = require('init/tasks/slideshows');

Sizer = require('util/sizer');

StillsCollection = require('stills/collection');

ImageCover = require('util/image-cover');

ImageLoad = require('util/image-load');

Modal = require('util/modal');

Balancer = require('balancer/balancer');

module.exports = function() {
  var awards_button, awards_content, awards_is_visible, explore_button, explore_content, explore_is_visible, get_tickets_link, hide_section, hide_watch_links, media_button, media_content, media_is_visible, poster_button, poster_content, poster_is_visible, show_section, show_watch_links, synopsis_button, synopsis_content, synopsis_is_visible, video_buttons, watch_now_button, watch_now_content, watch_now_is_visible, watch_now_links, www;
  slideshows('.slideshow');
  $('.slideshow .slide').each(function(index, el) {
    var play_button, slide;
    slide = $(el);
    play_button = slide.find('button.play-video');
    play_button.on('mouseenter', function() {
      return play_button.parents('.slide').addClass('may-play');
    });
    play_button.on('mouseleave', function() {
      return play_button.parents('.slide').removeClass('may-play');
    });
    return play_button.on('click', (function(_this) {
      return function() {
        var frame, id, video;
        id = play_button.parents('.slide').data('video-id');
        frame = $('<iframe />').attr('src', "https://youtube.com/embed/" + id + "?autoplay=1");
        video = $('<div />').addClass('responsive-video');
        return new Modal(video.append(frame));
      };
    })(this));
  });
  www = $('.ways-to-watch');
  watch_now_is_visible = false;
  watch_now_button = $('a.watch-now');
  watch_now_content = $('.watch-now-links');
  watch_now_links = $('.watch-now-links .source a');
  get_tickets_link = $('.buttons .tickets');
  synopsis_is_visible = false;
  synopsis_button = $('.synopsis-button');
  synopsis_content = $('.synopsis.text-content');
  awards_is_visible = false;
  awards_button = $('.awards-button');
  awards_content = $('.meta-group.awards');
  media_is_visible = false;
  media_button = $('.media-button');
  media_content = $('.media-columns');
  explore_is_visible = false;
  explore_button = $('.explore-button');
  explore_content = $('.explore-wrapper');
  poster_is_visible = false;
  poster_button = $('.poster-button');
  poster_content = $('.mobile-posters');
  video_buttons = $('.play-video');
  video_buttons.each(function(f, el) {
    var button, video_animation;
    button = $(el);
    video_animation = LOTTIE.loadAnimation({
      container: el,
      animationData: animationData,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: "Play Animation"
    });
    button.on('mouseenter', function(e) {
      return video_animation.play();
    });
    return button.on('mouseleave', function(e) {
      return video_animation.stop();
    });
  });
  $('.credit-list').each(function(index, el) {
    var arr, content, credit_list, credits, item, j, len, list;
    content = $(el);
    credit_list = content;
    list = [];
    credits = content.find('.content')[0].innerText;
    arr = credits.split(', ');
    for (j = 0, len = arr.length; j < len; j++) {
      item = arr[j];
      list += '<li>' + item + '</li>';
    }
    return credit_list.html('<ul>' + list + '</ul>');
  });
  show_watch_links = function() {
    watch_now_content.addClass('active');
    watch_now_button.addClass('active');
    if (window.history.pushState) {
      window.history.replaceState(null, null, '#watch-now');
    } else {
      window.location.hash = '#watch-now';
    }
    return watch_now_is_visible = true;
  };
  hide_watch_links = function() {
    watch_now_content.removeClass('active');
    watch_now_button.removeClass('active');
    if (window.history.pushState) {
      window.history.replaceState(null, null, window.location.pathname);
    } else {
      window.location.hash = '#watch-now';
    }
    return watch_now_is_visible = false;
  };
  if (window.location.hash === '#watch-now') {
    show_watch_links();
  }
  watch_now_button.on('click', function(e) {
    e.preventDefault();
    if (watch_now_is_visible) {
      return hide_watch_links();
    } else {
      return show_watch_links();
    }
  });
  watch_now_links.on('click', function(e) {
    if (ga) {
      return ga('send', 'event', {
        eventCategory: 'Outbound Link',
        eventAction: 'click',
        eventLabel: e.target.href
      });
    }
  });
  get_tickets_link.on('click', function(e) {
    if (ga) {
      return ga('send', 'event', {
        eventCategory: 'Outbound Link',
        eventAction: 'click',
        eventLabel: e.target.href
      });
    }
  });
  show_section = function(section) {
    if (section === "synopsis") {
      synopsis_content.addClass('active');
      synopsis_button.addClass('active');
      synopsis_is_visible = true;
    }
    if (section === "awards") {
      awards_content.addClass('active');
      awards_button.addClass('active');
      awards_is_visible = true;
    }
    if (section === "media") {
      media_content.addClass('active');
      media_button.addClass('active');
      media_is_visible = true;
    }
    if (section === "explore") {
      explore_content.addClass('active');
      explore_button.addClass('active');
      explore_is_visible = true;
    }
    if (section === "poster") {
      poster_content.addClass('active');
      poster_button.addClass('active');
      return poster_is_visible = true;
    }
  };
  hide_section = function(section) {
    if (section === "synopsis") {
      synopsis_content.removeClass('active');
      synopsis_button.removeClass('active');
      synopsis_is_visible = false;
    }
    if (section === "awards") {
      awards_content.removeClass('active');
      awards_button.removeClass('active');
      awards_is_visible = false;
    }
    if (section === "media") {
      media_content.removeClass('active');
      media_button.removeClass('active');
      media_is_visible = false;
    }
    if (section === "explore") {
      explore_content.removeClass('active');
      explore_button.removeClass('active');
      explore_is_visible = false;
    }
    if (section === "poster") {
      poster_content.removeClass('active');
      poster_button.removeClass('active');
      return poster_is_visible = false;
    }
  };
  if (synopsis_is_visible) {
    hide_section("synopsis");
  } else {
    show_section("synopsis");
  }
  synopsis_button.on('click', function(e) {
    var section;
    e.preventDefault();
    section = "synopsis";
    if (synopsis_is_visible) {
      return hide_section(section);
    } else {
      return show_section(section);
    }
  });
  awards_button.on('click', function(e) {
    var section;
    e.preventDefault();
    section = "awards";
    if (awards_is_visible) {
      return hide_section(section);
    } else {
      return show_section(section);
    }
  });
  media_button.on('click', function(e) {
    var section;
    e.preventDefault();
    section = "media";
    if (media_is_visible) {
      return hide_section(section);
    } else {
      return show_section(section);
    }
  });
  explore_button.on('click', function(e) {
    var section;
    e.preventDefault();
    section = "explore";
    if (explore_is_visible) {
      return hide_section(section);
    } else {
      return show_section(section);
    }
  });
  poster_button.on('click', function(e) {
    var section;
    e.preventDefault();
    section = "poster";
    if (poster_is_visible) {
      return hide_section(section);
    } else {
      return show_section(section);
    }
  });
  return $('.feature-tile img').each(function(index, img) {
    var $img, cover;
    $img = $(img);
    cover = new ImageCover($img);
    return new ImageLoad($img, function(i) {
      return cover.resize();
    });
  });
};
