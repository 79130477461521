var $, Balancer, Block, Column, Debounce;

$ = require('jquery');

Debounce = require('debounce');

Block = require('balancer/block');

Column = require('balancer/column');

module.exports = Balancer = (function() {
  function Balancer(context, containers, options) {
    this.context = context;
    this.containers = containers;
    if (options == null) {
      options = {};
    }
    this.options = $.extend(true, {
      minimumSize: 0,
      defaultColumn: 0,
      blockSelector: '.block',
      events: {
        onBeforeInitialDistribute: function() {},
        onAfterInitialDistribute: function() {},
        onBeforeDistribute: function() {},
        onAfterDistribute: function() {}
      }
    }, options);
    this.initd = false;
    this.columns = [];
    this.blocks = [];
    this.containers.each((function(_this) {
      return function(index, el) {
        return _this.columns.push(new Column($(el)));
      };
    })(this));
    this.context.find(this.options.blockSelector).each((function(_this) {
      return function(index, b) {
        var $block;
        $block = $(b);
        return _this.blocks.push(new Block($block));
      };
    })(this));
    $(window).on('resize', Debounce((function(_this) {
      return function() {
        return _this.distribute();
      };
    })(this), 100));
  }

  Balancer.prototype.distribute = function() {
    var block, column, i, j, k, len, len1, len2, ref, ref1, ref2;
    if (!this.initd) {
      this.raiseEvent('beforeInitialDistribute');
    }
    this.raiseEvent('beforeDistribute');
    ref = this.columns;
    for (i = 0, len = ref.length; i < len; i++) {
      column = ref[i];
      column.empty();
    }
    if (window.innerWidth >= this.options.minimumSize) {
      ref1 = this.blocks;
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        block = ref1[j];
        this.shortestColumn().addBlock(block);
      }
    } else {
      ref2 = this.blocks;
      for (k = 0, len2 = ref2.length; k < len2; k++) {
        block = ref2[k];
        this.columns[0].addBlock(block);
      }
    }
    if (!this.initd) {
      this.raiseEvent('afterInitialDistribute');
    }
    this.raiseEvent('afterDistribute');
    return this.initd = true;
  };

  Balancer.prototype.shortestColumn = function() {
    var column, i, len, ref, shortest;
    if (!shortest) {
      shortest = this.columns[this.options.defaultColumn];
    }
    ref = this.columns;
    for (i = 0, len = ref.length; i < len; i++) {
      column = ref[i];
      if (column.getTotalHeight() < shortest.getTotalHeight()) {
        shortest = column;
      }
    }
    return shortest;
  };

  Balancer.prototype.raiseEvent = function(event) {
    var base;
    return typeof (base = this.options.events)[event] === "function" ? base[event](this) : void 0;
  };

  return Balancer;

})();
