var $;

$ = require('jquery');

module.exports = function($form) {
  var arr, i, len, obj, pair;
  obj = {};
  arr = $form.serializeArray();
  for (i = 0, len = arr.length; i < len; i++) {
    pair = arr[i];
    if (obj[pair.name] !== void 0) {
      if (!Array.isArray(obj[pair.name])) {
        obj[pair.name] = [obj[pair.name]];
      }
      obj[pair.name].push(pair.value || '');
    } else {
      obj[pair.name] = pair.value || '';
    }
  }
  return obj;
};
