var Loader;

module.exports = Loader = (function() {
  function Loader(parent, type) {
    this.type = type != null ? type : 'fullscreen';
    this.parent = parent || $(document.body);
    this.el = this.render();
    this.open();
  }

  Loader.prototype.open = function() {
    this.el.appendTo(this.parent);
    return this.parent.addClass('loader-active');
  };

  Loader.prototype.close = function() {
    this.el.remove();
    return this.parent.removeClass('loader-active');
  };

  Loader.prototype.render = function() {
    return $('<aside/>').addClass(['loader', this.type].join(' '));
  };

  return Loader;

})();
