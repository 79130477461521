var $, Carousel, ImageCover, ImageLoad, LOTTIE, Slide, animationData;

$ = require('jquery');

LOTTIE = require('lottie-web');

animationData = require('../data/data.json');

ImageCover = require('util/image-cover');

ImageLoad = require('util/image-load');

Carousel = require('carousel/carousel');

Slide = require('carousel/slide');

module.exports = function() {
  var video_buttons, video_player;
  video_player = $('.video-preview');
  video_buttons = $('.play-video');
  video_player.each(function(f, el) {
    var button, image, video_animation;
    image = $(el);
    button = el.querySelector('.play-video');
    if (button) {
      video_animation = LOTTIE.loadAnimation({
        container: button,
        animationData: animationData,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        name: "Play Animation"
      });
      image.on('mouseenter', function(e) {
        return video_animation.play();
      });
      return image.on('mouseleave', function(e) {
        return video_animation.stop();
      });
    }
  });
  $('section.header .slideshow').each(function(index, carousel) {
    var $el;
    $el = $(carousel);
    return new Carousel($el, {
      auto: false,
      indicators: $el.find('.indicators'),
      slideType: Slide
    });
  });
  $('section.header .responsive-image').each(function(index, header) {
    var $header, $img, cover;
    $header = $(header);
    $img = $header.find('img');
    cover = new ImageCover($img);
    return new ImageLoad($img, function(i) {
      cover.resize();
      return $header.addClass('ready');
    });
  });
  $('section.explore .feature-tile').each(function(index, feature) {
    var $feature, $img, cover;
    $feature = $(feature);
    $img = $feature.find('img');
    cover = new ImageCover($img);
    return new ImageLoad($img, function(i) {
      cover.resize();
      return $feature.addClass('ready');
    });
  });
  $('section.intro .contents .chapter').each(function(index, chapter) {
    var $chapter;
    $chapter = $(chapter);
    return $chapter.on('click', function(e) {
      var destination;
      e.preventDefault();
      destination = $($chapter.attr('href'));
      return $('html, body').animate({
        scrollTop: destination.offset().top - 200
      }, {
        duration: 500
      });
    });
  });
  return $('section.story-content .slideshow').each(function(index, carousel) {
    var $el;
    $el = $(carousel);
    return new Carousel($el, {
      auto: false,
      indicators: $el.find('.indicators'),
      slideType: Slide
    });
  });
};
