var Loader, SerializeObject;

Loader = require('util/loader');

SerializeObject = require('util/serialize-object');

module.exports = function() {
  $('.quantity-select').each(function(index, el) {
    var decrementButton, decrementQty, display, incrementButton, incrementQty, initialVal, input, messages, ui;
    ui = $(el);
    input = ui.find('.quantity-input');
    display = ui.find('.quantity-value');
    messages = ui.find('.messages');
    incrementButton = ui.find('.quantity-increment');
    decrementButton = ui.find('.quantity-decrement');
    initialVal = parseInt(input.val());
    incrementQty = function() {
      var currentVal, maxVal, newVal;
      currentVal = parseInt(input.val());
      maxVal = parseInt(ui.data('qty-max')) || 2e308;
      if (currentVal + 1 <= maxVal) {
        newVal = currentVal + 1;
      } else {
        newVal = maxVal;
      }
      input.val(newVal);
      return display.text(newVal);
    };
    decrementQty = function() {
      var currentVal, minVal, newVal;
      currentVal = parseInt(input.val());
      minVal = parseInt(ui.data('qty-min')) || 0;
      if (currentVal - 1 >= minVal) {
        newVal = currentVal - 1;
      } else {
        newVal = minVal;
      }
      input.val(newVal);
      return display.text(newVal);
    };
    incrementButton.on('click', incrementQty);
    return decrementButton.on('click', decrementQty);
  });
  return $('form.waitlist').each(function(index, f) {
    var $f, $messages, indicator;
    $f = $(f);
    $messages = $f.find('.messages');
    indicator = null;
    return $f.on('submit', function(e) {
      var payload;
      e.preventDefault();
      payload = SerializeObject($f);
      return $.ajax({
        method: 'POST',
        url: '/',
        data: payload,
        beforeSend: function() {
          indicator = new Loader($f.find('label'), 'inline');
          $f.find(':input').attr('disabled', true);
          return $f.find('.error').each(function(index, el) {
            return $(el).empty();
          });
        },
        success: function(data) {
          var $err, $errContainer, $success, error, errors, key, ref, results;
          indicator.close();
          indicator = null;
          if (data.success) {
            $success = $('<p/>').text('Thanks! We’ll let you know when we make more.');
            $messages.html($success);
            $messages.show();
            $(document.body).trigger('trackedAction', {
              trackedAction: 'waitlist_add',
              trackedActionParams: payload.subjectTitle
            });
            return $f.on('submit', function(e) {
              return e.preventDefault();
            });
          } else {
            $f.find(':input').attr('disabled', false);
            ref = data.error;
            results = [];
            for (key in ref) {
              errors = ref[key];
              $errContainer = $f.find(":input[name='" + key + "']").siblings('.error');
              results.push((function() {
                var i, len, results1;
                results1 = [];
                for (i = 0, len = errors.length; i < len; i++) {
                  error = errors[i];
                  $err = $('<li/>').addClass('error').text(error);
                  results1.push($errContainer.append($err));
                }
                return results1;
              })());
            }
            return results;
          }
        }
      });
    });
  });
};
