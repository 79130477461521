var $, Sizer;

$ = require('jquery');

Sizer = require('util/sizer');

module.exports = function(selector, load) {
  var $header, $list, $listImage, $w, active, header_height, list, list_offset, margin, onEnter, resolve_stickiness, stick, stuck, unstick;
  if ($(selector)[0]) {
    list = $(selector);
  } else {
    return;
  }
  $w = $(window);
  $header = $('header');
  $list = $('.media-list-wrapper');
  $listImage = $('.media-list-image');
  header_height = 0;
  margin = $(window).height() / 5;
  list_offset = 0;
  stuck = false;
  active = false;
  header_height = $header.outerHeight();
  onEnter = function() {
    var mediaImage, still;
    still = $('.media-list-title.first-in-list').attr('data-image');
    mediaImage = $('.media-list-image img');
    return mediaImage.attr('src', still);
  };
  $w.on('load', onEnter);
  if (load) {
    onEnter();
  }
  stick = function() {
    $listImage.addClass('sticky');
    return stuck = true;
  };
  unstick = function() {
    $listImage.removeClass('sticky');
    return stuck = false;
  };
  resolve_stickiness = function() {
    var pos;
    pos = window.scrollY || document.documentElement.scrollTop;
    list_offset = $list.offset().top;
    if (pos + margin > list_offset && !stuck) {
      stick();
    }
    if (pos + margin < list_offset && stuck) {
      return unstick();
    }
  };
  new Sizer({
    min: 1024,
    onEnter: function() {
      resolve_stickiness();
      return $(window).on('scroll.list', resolve_stickiness);
    },
    onExit: function() {
      unstick();
      return $(window).off('scroll.list');
    }
  });
  return $('.media-tiles .media-list-title').each(function(f, el) {
    var mediaTitle, still;
    mediaTitle = $(el);
    still = mediaTitle.attr('data-image');
    mediaTitle.on('mouseenter', function(e) {
      var mediaImage;
      mediaImage = $('.media-list-image img');
      mediaImage.attr('src', still);
      $listImage.addClass('active');
      if (!active) {
        return active = true;
      }
    });
    return mediaTitle.on('mouseleave', function(e) {
      if (active) {
        $listImage.removeClass('active');
        return active = false;
      }
    });
  });
};
