var $, ImageCover, ImageLoad, Sizer;

$ = require('jquery');

Sizer = require('util/sizer');

ImageLoad = require('util/image-load');

ImageCover = require('util/image-cover');

module.exports = function() {
  return $('.responsive-image img').each(function(index, img) {
    var $image, lazy;
    $image = $(img);
    lazy = new ImageCover($image);
    return new ImageLoad($image, function(el) {
      return lazy.resize();
    });
  });
};
