var $, LazyLoad;

$ = require('jquery');

module.exports = LazyLoad = (function() {
  function LazyLoad(el, callback) {
    this.el = el;
    this.callback = callback;
    if (this.is_loaded()) {
      this.after();
    } else {
      this.listen();
    }
  }

  LazyLoad.prototype.listen = function() {
    return this.el.on('load', (function(_this) {
      return function(e) {
        return _this.after();
      };
    })(this));
  };

  LazyLoad.prototype.is_loaded = function() {
    return this.loaded = this.el.get(0).complete;
  };

  LazyLoad.prototype.after = function() {
    this.loaded = true;
    this.el.addClass('loaded');
    return this.callback(this.el);
  };

  return LazyLoad;

})();
