var $, Loader, addErrors, removeErrors;

$ = require('jquery');

Loader = require('util/loader');

removeErrors = function($form) {
  var $errorsContainer;
  $errorsContainer = $form.find('.errors');
  $errorsContainer.empty();
  return $errorsContainer.removeClass('with-errors');
};

addErrors = function($form, fields) {
  var $errorsContainer, error, errors, field, results;
  results = [];
  for (field in fields) {
    errors = fields[field];
    $errorsContainer = $form.find("[data-field='" + field + "'] .errors");
    results.push((function() {
      var j, len, results1;
      results1 = [];
      for (j = 0, len = errors.length; j < len; j++) {
        error = errors[j];
        $errorsContainer.append($('<li/>').text(error));
        results1.push($errorsContainer.addClass('with-errors'));
      }
      return results1;
    })());
  }
  return results;
};

module.exports = function() {
  return $('.greenhouse-form').each(function(index, form) {
    var $form, loader;
    $form = $(form);
    $form.attr('novalidate', true);
    loader = null;
    $form.on('submit', function(e) {
      e.preventDefault();
      return $.ajax({
        method: 'POST',
        data: new FormData(form),
        contentType: false,
        processData: false,
        beforeSend: function() {
          $form.find(':input').each(function(i, input) {
            return $(input).prop('disabled', true);
          });
          $form.addClass('loading');
          return loader = new Loader($form.find('.submit label'), 'inline');
        },
        success: function(response) {
          removeErrors($form);
          loader.close();
          if (response.success) {
            return window.location = '/jobs/application-confirmation';
          } else {
            $form.find(':input').each(function(i, input) {
              return $(input).prop('disabled', false);
            });
            $form.removeClass('loading');
            return addErrors($form, response.errors);
          }
        }
      });
    });
    $(".focus-fade").focus(function() {
      $(this).prev().fadeOut(200);
    });
    return $(".focus-fade").focusout(function() {
      $(this).prev().fadeIn(200);
    });
  });
};
