var Column;

module.exports = Column = (function() {
  function Column(el) {
    this.el = el;
    this.blocks = [];
  }

  Column.prototype.addBlock = function(block) {
    this.el.append(block.el);
    return this.blocks.push(block);
  };

  Column.prototype.empty = function() {
    var block, i, len, ref;
    ref = this.blocks;
    for (i = 0, len = ref.length; i < len; i++) {
      block = ref[i];
      block.el.detach();
    }
    return this.blocks = [];
  };

  Column.prototype.getTotalHeight = function() {
    var block, height, i, len, ref;
    height = 0;
    ref = this.blocks;
    for (i = 0, len = ref.length; i < len; i++) {
      block = ref[i];
      height = height + block.getHeight();
    }
    return height;
  };

  return Column;

})();
