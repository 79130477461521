var $, ImageCover, ImageLoad;

$ = require('jquery');

ImageLoad = require('util/image-load');

ImageCover = require('util/image-cover');

module.exports = function(selector, context) {
  var imageContainers;
  if (context == null) {
    context = null;
  }
  if (context) {
    imageContainers = context.find(selector);
  } else {
    imageContainers = $(selector);
  }
  return imageContainers.each(function(index, el) {
    var $container, $img;
    $container = $(el);
    $img = $container.find('img');
    return new ImageLoad($img, function(i) {
      return i.addClass('loaded');
    });
  });
};
