var $, ImageCover, Sizer, lazy_images, list_image;

$ = require('jquery');

Sizer = require('util/sizer');

ImageCover = require('util/image-cover');

list_image = require('init/tasks/list-image');

lazy_images = require('init/tasks/lazy-images');

module.exports = function() {
  var allFilms, allFilmsArr, allTileHeights, button, expand, featFilms, featFilmsArr, featTileHeights, hide_list, hide_sorting, list, list_expandable, list_reveal, list_total, more, resetArrayHeights, setupMargins, showMore, show_sorting, sorting_are_visible, sorting_button, sorting_content, updateArrayHeights;
  list_expandable = true;
  list_reveal = 18;
  list_total = 0;
  sorting_are_visible = false;
  sorting_button = $('a.active-sorting');
  sorting_content = $('.controls');
  list = $('.media-list');
  list_total = list.length;
  more = $('.show-more-wrapper');
  button = $('.show-more-wrapper');
  showMore = $('.show-more-button');
  allTileHeights = [];
  featTileHeights = [];
  allFilms = $('section.all-films .media-tiles');
  allFilmsArr = $('section.all-films .media-tiles .media-tile');
  featFilms = $('section.features .feature-group .media-tiles');
  featFilmsArr = $('section.features .feature-group .media-tiles .media-tile');
  setupMargins = function(arr, heights) {
    var aaaaab, aaaaba, aaaabb, aaabaa, aaabab, aaabba, aabaaa, aabaab, aababa, aababb, aabbaa, aabbab, aabbba, abaaaa, abaaab, abaaba, abaabb, ababaa, ababab, ababba, abbaaa, abbaab, abbaba, abbabb, abbbaa, abbbab, abbbba, baaaaa, baaaab, baaaba, baaabb, baabaa, baabab, baabba, babaaa, babaab, bababa, bababb, babbaa, babbab, babbba, bbaaaa, bbaaab, bbaaba, bbaabb, bbabaa, bbabab, bbabba, layout, standard;
    standard = [0, 0, 0, 0, '-12.3%', '-16.3%', 0, '-7.8%', 0];
    aaabaa = [0, 0, 0, 0, '-12.8%', '-16.3%', 0, '-12.8%', '-3.7%'];
    aaabba = [0, 0, 0, 0, '-12.8%', '-16.3%', 0, '-9.4%', '-3.7%'];
    aaaaba = [0, 0, 0, 0, '-12.8%', '-16.3%', 0, '-5.7%', 0];
    aaabab = [0, 0, 0, 0, '-12.8%', '-16.3%', 0, '-12.3%', 0];
    aaaabb = [0, 0, 0, 0, '-12.8%', '-16.3%', '-3.5%', '-9.4%', 0];
    aaaaab = [0, 0, 0, 0, '-12.8%', '-16.3%', '-3.5%', '-12.8%', 0];
    baabaa = [0, 0, 0, 0, '-16.3%', '-20.3%', 0, '-16.8%', '-7.8%'];
    baabba = [0, 0, 0, 0, '-16.3%', '-20.3%', 0, '-12.8%', '-7.8%'];
    baaaaa = [0, 0, 0, 0, '-16.3%', '-20.3%', 0, '-12.8%', '-4.4%'];
    baaaba = [0, 0, 0, 0, '-16.3%', '-20.3%', 0, '-9.4%', '-4.4%'];
    baabab = [0, 0, 0, 0, '-16.3%', '-20.3%', 0, '-16.3%', '-4.4%'];
    baaabb = [0, 0, 0, 0, '-16.3%', '-20.3%', 0, '-9.4%', 0];
    baaaab = [0, 0, 0, 0, '-16.3%', '-20.3%', 0, '-12.8%', 0];
    bbabaa = [0, 0, 0, 0, '-12.8%', '-20.3%', 0, '-12.8%', '-7.8%'];
    bbabba = [0, 0, 0, 0, '-12.8%', '-20.3%', 0, '-9%', '-7.8%'];
    bbaaaa = [0, 0, 0, 0, '-12.8%', '-20.3%', 0, '-9.4%', '-4.4%'];
    bbaaba = [0, 0, 0, 0, '-12.8%', '-20.3%', 0, '-5.9%', '-4.4%'];
    bbabab = [0, 0, 0, 0, '-12.8%', '-20.3%', 0, '-12.8%', '-4.4%'];
    bbaabb = [0, 0, 0, 0, '-12.8%', '-20.3%', 0, '-4.4%', 0];
    bbaaab = [0, 0, 0, 0, '-12.8%', '-20.3%', 0, '-9.4%', 0];
    ababaa = [0, 0, 0, 0, '-9.1%', '-16.3%', 0, '-9.1%', '-3.8%'];
    ababba = [0, 0, 0, 0, '-9.1%', '-16.3%', 0, '-5.7%', '-3.8%'];
    abaaaa = [0, 0, 0, 0, '-9.1%', '-16.3%', 0, '-5.6%', 0];
    abaaba = [0, 0, 0, 0, '-9.1%', '-16.3%', 0, '-2%', 0];
    ababab = [0, 0, 0, 0, '-9.1%', '-16.3%', 0, '-9.1%', 0];
    abaabb = [0, 0, 0, 0, '-9.1%', '-16.3%', '-3.4%', '-4.4%', 0];
    abaaab = [0, 0, 0, 0, '-9.1%', '-16.3%', '-3.4%', '-9.4%', 0];
    babbaa = [0, 0, 0, 0, '-16.2%', '-16.3%', 0, '-16.1%', '-3.8%'];
    babbba = [0, 0, 0, 0, '-16.2%', '-16.3%', 0, '-12.6%', '-3.8%'];
    babaaa = [0, 0, 0, 0, '-16.2%', '-16.3%', 0, '-12.6%', 0];
    bababa = [0, 0, 0, 0, '-16.2%', '-16.3%', 0, '-9.1%', 0];
    babbab = [0, 0, 0, 0, '-16.2%', '-16.3%', 0, '-16.1%', 0];
    bababb = [0, 0, 0, 0, '-16.2%', '-16.3%', '-3.4%', '-12.6%', 0];
    babaab = [0, 0, 0, 0, '-16.2%', '-16.3%', '-3.4%', '-16.1%', 0];
    abbbaa = [0, 0, 0, 0, '-9.1%', '-12.8%', 0, '-9.1%', 0];
    abbbba = [0, 0, 0, 0, '-9.1%', '-12.8%', 0, '-5.7%', 0];
    abbaaa = [0, 0, 0, 0, '-9.1%', '-12.8%', '-3.3%', '-9.1%', 0];
    abbaba = [0, 0, 0, 0, '-9.1%', '-12.8%', '-3.3%', '-5.7%', 0];
    abbbab = [0, 0, 0, 0, '-9.1%', '-12.8%', '-3.4%', '-12.6%', 0];
    abbabb = [0, 0, 0, 0, '-9.1%', '-12.8%', '-6.9%', '-9.1%', 0];
    abbaab = [0, 0, 0, 0, '-9.1%', '-12.8%', '-6.9%', '-12.6%', 0];
    aabbaa = [0, 0, 0, 0, '-12.8%', '-12.8%', 0, '-12.8%', 0];
    aabbba = [0, 0, 0, 0, '-12.8%', '-12.8%', 0, '-9.3%', 0];
    aabaaa = [0, 0, 0, 0, '-12.8%', '-12.8%', '-3.3%', '-12.7%', 0];
    aababa = [0, 0, 0, 0, '-12.8%', '-12.8%', '-3.3%', '-9.1%', 0];
    aabbab = [0, 0, 0, 0, '-12.8%', '-12.8%', '-3.4%', '-16.1%', 0];
    aababb = [0, 0, 0, 0, '-12.8%', '-12.8%', '-6.9%', '-12.7%', 0];
    aabaab = [0, 0, 0, 0, '-12.8%', '-12.8%', '-6.9%', '-16.2%', 0];
    layout = standard;
    if (heights[0] === heights[1] && heights[0] === heights[2]) {
      console.log('aaa');
      if (heights[3] > heights[4] && heights[3] > heights[5]) {
        layout = aaabaa;
      } else if (heights[3] > heights[5] && heights[4] > heights[5]) {
        layout = aaabba;
      } else if (heights[4] > heights[3] && heights[4] > heights[5]) {
        layout = aaaaba;
      } else if (heights[3] > heights[4] && heights[5] > heights[4]) {
        layout = aaabab;
      } else if (heights[4] > heights[3] && heights[5] > heights[3]) {
        layout = aaaabb;
      } else if (heights[5] > heights[3] && heights[5] > heights[4]) {
        layout = aaaaab;
      } else {
        layout = standard;
      }
    }
    if (heights[0] > heights[1] && heights[0] > heights[2]) {
      console.log('baa');
      if (heights[3] > heights[4] && heights[3] > heights[5]) {
        layout = baabaa;
      } else if (heights[3] > heights[5] && heights[4] > heights[5]) {
        layout = baabba;
      } else if (heights[4] > heights[3] && heights[4] > heights[5]) {
        layout = baaaba;
      } else if (heights[3] > heights[4] && heights[5] > heights[4]) {
        layout = baabab;
      } else if (heights[4] > heights[3] && heights[5] > heights[3]) {
        layout = baaabb;
      } else if (heights[5] > heights[3] && heights[5] > heights[4]) {
        layout = baaaab;
      } else {
        layout = baaaaa;
      }
    }
    if (heights[0] > heights[2] && heights[1] > heights[2]) {
      console.log('bba');
      if (heights[3] > heights[4] && heights[3] > heights[5]) {
        layout = bbabaa;
      } else if (heights[3] > heights[5] && heights[4] > heights[5]) {
        layout = bbabba;
      } else if (heights[4] > heights[3] && heights[4] > heights[5]) {
        layout = bbaaba;
      } else if (heights[4] > heights[3] && heights[5] > heights[3]) {
        layout = bbaabb;
      } else if (heights[5] > heights[3] && heights[5] > heights[4]) {
        layout = bbaaab;
      } else if (heights[3] > heights[4] && heights[5] > heights[4]) {
        layout = bbabab;
      } else {
        layout = bbaaaa;
      }
    }
    if (heights[1] > heights[0] && heights[1] > heights[2]) {
      console.log('aba');
      if (heights[3] > heights[4] && heights[3] > heights[5]) {
        layout = ababaa;
      } else if (heights[3] > heights[5] && heights[4] > heights[5]) {
        layout = ababba;
      } else if (heights[4] > heights[3] && heights[4] > heights[5]) {
        layout = abaaba;
      } else if (heights[4] > heights[3] && heights[5] > heights[3]) {
        layout = abaabb;
      } else if (heights[5] > heights[3] && heights[5] > heights[4]) {
        layout = abaaab;
      } else if (heights[3] > heights[4] && heights[5] > heights[4]) {
        layout = ababab;
      } else {
        layout = abaaaa;
      }
    }
    if (heights[0] > heights[1] && heights[2] > heights[1]) {
      console.log('bab');
      if (heights[3] > heights[4] && heights[3] > heights[5]) {
        layout = babbaa;
      } else if (heights[3] > heights[5] && heights[4] > heights[5]) {
        layout = babbba;
      } else if (heights[4] > heights[3] && heights[4] > heights[5]) {
        layout = bababa;
      } else if (heights[4] > heights[3] && heights[5] > heights[3]) {
        layout = bababb;
      } else if (heights[5] > heights[3] && heights[5] > heights[4]) {
        layout = babaab;
      } else if (heights[3] > heights[4] && heights[5] > heights[4]) {
        layout = babbab;
      } else {
        layout = babaaa;
      }
    }
    if (heights[1] > heights[0] && heights[2] > heights[0]) {
      console.log('abb');
      if (heights[3] > heights[4] && heights[3] > heights[5]) {
        layout = abbbaa;
      } else if (heights[3] > heights[5] && heights[4] > heights[5]) {
        layout = abbbba;
      } else if (heights[4] > heights[3] && heights[4] > heights[5]) {
        layout = abbaba;
      } else if (heights[4] > heights[3] && heights[5] > heights[3]) {
        layout = abbabb;
      } else if (heights[5] > heights[3] && heights[5] > heights[4]) {
        layout = abbaab;
      } else if (heights[3] > heights[4] && heights[5] > heights[4]) {
        layout = abbbab;
      } else {
        layout = abbaaa;
      }
    }
    if (heights[2] > heights[0] && heights[2] > heights[1]) {
      console.log('aab');
      if (heights[3] > heights[4] && heights[3] > heights[5]) {
        layout = aabbaa;
      } else if (heights[3] > heights[5] && heights[4] > heights[5]) {
        layout = aabbba;
      } else if (heights[4] > heights[3] && heights[4] > heights[5]) {
        layout = aababa;
      } else if (heights[4] > heights[3] && heights[5] > heights[3]) {
        layout = aababb;
      } else if (heights[5] > heights[3] && heights[5] > heights[4]) {
        layout = aabaab;
      } else if (heights[3] > heights[4] && heights[5] > heights[4]) {
        layout = aabbab;
      } else {
        layout = aabaaa;
      }
    }
    return arr.each(function(i, el) {
      var tile;
      tile = $(el);
      return tile.css('margin-top', layout[i]);
    });
  };
  updateArrayHeights = function() {
    allFilmsArr = $('section.all-films .media-tiles .media-tile');
    allFilmsArr.each(function(i, el) {
      var copyHeight, tile;
      tile = $(el);
      copyHeight = tile.find('figcaption').outerHeight();
      allTileHeights.push(copyHeight);
      if (!tile.next().hasClass('media-tile')) {
        return setupMargins(allFilmsArr, allTileHeights);
      }
    });
    return featFilmsArr.each(function(i, el) {
      var copyHeight, tile;
      tile = $(el);
      copyHeight = tile.find('figcaption').outerHeight();
      featTileHeights.push(copyHeight);
      if (!tile.next().hasClass('media-tile')) {
        return setupMargins(featFilmsArr, featTileHeights);
      }
    });
  };
  resetArrayHeights = function() {
    allFilmsArr.each(function(i, el) {
      var tile;
      tile = $(el);
      return tile.css('margin-top', '0');
    });
    return featFilmsArr.each(function(i, el) {
      var tile;
      tile = $(el);
      return tile.css('margin-top', '0');
    });
  };
  new Sizer({
    onActive: function() {
      if ($(window).width() >= 640) {
        allTileHeights.length = 0;
        updateArrayHeights();
      }
      if ($(window).width() < 640) {
        allTileHeights.length = 0;
        return resetArrayHeights();
      }
    }
  });
  show_sorting = function() {
    sorting_content.addClass('active');
    sorting_button.addClass('active');
    return sorting_are_visible = true;
  };
  hide_sorting = function() {
    sorting_content.removeClass('active');
    sorting_button.removeClass('active');
    return sorting_are_visible = false;
  };
  if (window.location.hash === '#sorting') {
    show_sorting();
  }
  sorting_button.on('click', function(e) {
    e.preventDefault();
    if (sorting_are_visible) {
      return hide_sorting();
    } else {
      return show_sorting();
    }
  });
  list_image('.media-list-image');
  if (list_expandable && list_reveal < list_total) {
    more.css('display', 'block');
  }
  expand = function() {
    list_reveal += 12;
    list.each((function(_this) {
      return function(index, el) {
        var item;
        item = $(el);
        if (index > list_reveal) {
          return item.css('display', 'none');
        } else {
          return item.css('display', 'block');
        }
      };
    })(this));
    if (list_reveal >= list_total) {
      list_expandable = false;
      return showMore.css('display', 'none');
    }
  };
  hide_list = function() {
    list = $('.media-list');
    showMore = $('.show-more-button');
    more = $('.show-more-wrapper');
    showMore.on('click', (function(_this) {
      return function() {
        if (list_expandable) {
          return expand();
        }
      };
    })(this));
    if (list_expandable && list_reveal < list_total) {
      more.css('display', 'block');
    } else {
      more.css('display', 'none');
    }
    return list.each((function(_this) {
      return function(index, el) {
        var item;
        item = $(el);
        if (index > list_reveal) {
          return item.css('display', 'none');
        }
      };
    })(this));
  };
  hide_list();
  $('main.films img').each(function(index, img) {
    var $img;
    $img = $(img);
    return new ImageCover($img);
  });
  return $('section.all-films').each(function(index, el) {
    var container, controls, sorting, tiles;
    container = $(el);
    tiles = container.find('.media-tiles');
    controls = container.find('.controls');
    sorting = container.find('.active-sorting');
    return container.find('input[name=sort]').on('change', function() {
      var select, thumbLimit, value;
      if (sorting_are_visible) {
        hide_sorting();
      }
      select = $(this);
      value = $(this).attr('data-name');
      thumbLimit = $(this).attr('data-thumb-limit');
      return $.ajax('/ajax/film-tiles', {
        data: {
          sort: select.val(),
          thumbLim: thumbLimit
        },
        beforeSend: function() {
          return tiles.addClass('loading');
        },
        success: function(res) {
          tiles.html(res);
          tiles.removeClass('loading');
          lazy_images('.responsive-image', tiles);
          list_image('.media-list-image', true);
          allTileHeights.length = 0;
          if ($(window).width() >= 640) {
            window.setTimeout(function() {
              updateArrayHeights();
              return hide_list();
            }, 250);
          }
          sorting.html(value);
          return tiles.find('img').each(function(index, img) {
            return new ImageCover($(img));
          });
        },
        error: function() {
          return tiles.html('Sorry, something went wrong.');
        }
      });
    });
  });
};
