module.exports = function() {
  return $('.field-group.address-address').each(function(index, ui) {
    var $countries, $states, $statesCustom, $ui;
    $ui = $(ui);
    $countries = $ui.find('select.address-country');
    $states = $ui.find('select.address-state');
    $statesCustom = $ui.find('input.address-state-custom');
    $countries.on('change', function(e) {
      var countryGroup, countryId, state, stateId;
      countryId = $countries.val();
      countryGroup = $states.children("optgroup[data-country-id='" + countryId + "']");
      if (countryGroup.length) {
        $states.prop('disabled', false);
        $statesCustom.prop('disabled', true);
        $statesCustom.hide();
        $states.show();
      } else {
        $states.prop('disabled', true);
        $states.hide();
        $statesCustom.prop('disabled', false);
        $statesCustom.show();
      }
      state = countryGroup.children('option:checked:first');
      if (state.length) {
        stateId = state.val();
      } else {
        stateId = countryGroup.children('option:first').val();
      }
      return $states.val(stateId);
    });
    return $countries.trigger('change');
  });
};
