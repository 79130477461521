module.exports = function() {
  return $('.tweet.is-embedded').each(function(index, t) {
    var $embed, $tweet;
    $tweet = $(t);
    $embed = $tweet.find('blockquote');
    return twttr.widgets.createTweet($embed.data('tweet-id'), $tweet.get(0), {
      linkColor: '#888888',
      align: 'center'
    }).then(function(el) {
      return $embed.hide();
    });
  });
};
