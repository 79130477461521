var $, Still, StillsCollection,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

$ = require('jquery');

Still = require('stills/still');

module.exports = StillsCollection = (function() {
  function StillsCollection(context, trigger, delay) {
    this.context = context;
    this.trigger = trigger;
    if (delay == null) {
      delay = 0;
    }
    this.stills = [];
    this.current = null;
    this.interval = null;
    this.context.find('.still').each((function(_this) {
      return function(index, el) {
        return _this.stills.push(new Still($(el), _this));
      };
    })(this));
    window.setTimeout((function(_this) {
      return function() {
        if (_this.stills.length) {
          return _this.pick(0);
        }
      };
    })(this), delay);
    this.trigger.on('mouseenter', (function(_this) {
      return function() {
        return _this.play();
      };
    })(this));
    this.trigger.on('mouseleave', (function(_this) {
      return function() {
        return _this.pause();
      };
    })(this));
  }

  StillsCollection.prototype.play = function() {
    this.context.addClass('playing');
    return this.interval = window.setInterval((function(_this) {
      return function() {
        if (_this.hasNextReady().length) {
          return _this.pick(_this.nextReady());
        }
      };
    })(this), 2000);
  };

  StillsCollection.prototype.pause = function() {
    this.context.removeClass('playing');
    return window.clearInterval(this.interval);
  };

  StillsCollection.prototype.nextReady = function() {
    var check;
    check = this.next();
    while (!this.stills[check].ready) {
      check = this.next(check);
    }
    return check;
  };

  StillsCollection.prototype.hasNextReady = function() {
    var check, checked, ready;
    checked = [];
    ready = [];
    check = this.next();
    while (!(ready.length || indexOf.call(checked, check) >= 0)) {
      checked.push(check);
      if (this.stills[check].ready) {
        ready.push(this.stills[check]);
      }
      check = this.next(check);
    }
    return ready;
  };

  StillsCollection.prototype.next = function(from) {
    if (from == null) {
      from = null;
    }
    if (from === null) {
      from = this.current;
    }
    if (from + 1 < this.stills.length) {
      return from + 1;
    } else {
      return 0;
    }
  };

  StillsCollection.prototype.prev = function(from) {
    if (from == null) {
      from = null;
    }
    if (from === null) {
      from = this.current;
    }
    if (from - 1 >= 0) {
      return from - 1;
    } else {
      return this.stills.length - 1;
    }
  };

  StillsCollection.prototype.pick = function(index) {
    var i, j, len, ref, still;
    if (index !== this.current && this.stills[index]) {
      if (!(this.next(index) === index || this.stills[this.next(index)].ready)) {
        this.stills[this.next(index)].load();
      }
      ref = this.stills;
      for (i = j = 0, len = ref.length; j < len; i = ++j) {
        still = ref[i];
        if (index !== i) {
          still.deactivate();
        }
      }
      this.stills[index].activate();
      return this.current = index;
    }
  };

  return StillsCollection;

})();
