var $, Scroller;

$ = require('jquery');

module.exports = Scroller = (function() {
  function Scroller(options) {
    if (options == null) {
      options = {};
    }
    this.screen = $(window);
    this.active = null;
    this.count = 0;
    this.position = 0;
    this.options = $.extend({
      min: 0,
      max: 2e308,
      once: false,
      onDown: function(e) {},
      onUp: function(e) {},
      onActive: function(e) {}
    }, options);
    this.screen.on('scroll', (function(_this) {
      return function(e) {
        return _this.scrolling(e);
      };
    })(this));
  }

  Scroller.prototype.scrolling = function(e) {
    var current, scrollPos;
    current = (window.innerWidth >= this.options.min) && (window.innerWidth < this.options.max);
    scrollPos = this.screen.scrollTop();
    if (this.active === true && current === true) {
      if (scrollPos > this.position) {
        this.options.onDown(e);
      }
      if (scrollPos <= this.position) {
        this.options.onUp(e);
      }
    }
    this.position = scrollPos;
    this.active = current;
    if (this.active) {
      ++this.count;
      if (!(this.options.once && this.count > 1)) {
        return this.options.onActive(e);
      }
    }
  };

  return Scroller;

})();
