var Block;

module.exports = Block = (function() {
  function Block(el) {
    this.el = el;
  }

  Block.prototype.getHeight = function() {
    return this.el.outerHeight();
  };

  return Block;

})();
