var Meta, prepAddressAttrs, prepSingleDataAttr;

Meta = require('util/meta');

prepSingleDataAttr = function(element, attr) {
  return element.attr("data-" + attr);
};

prepAddressAttrs = function(element) {
  return {
    address_line1: prepSingleDataAttr(element, 'address_line1'),
    address_line2: prepSingleDataAttr(element, 'address_line2'),
    address_city: prepSingleDataAttr(element, 'address_city'),
    address_state: prepSingleDataAttr(element, 'address_state'),
    address_zip: prepSingleDataAttr(element, 'address_zip'),
    address_country: prepSingleDataAttr(element, 'address_country')
  };
};

module.exports = function() {
  $('form.stripe').each(function(index, form) {
    var $form, $stripeCardNumberInput, $stripeCvcInput, $stripeExpirationInput, $stripeMessages, $stripeTokenInput, $submitBtn, $tos, cardCvc, cardExpiry, cardNumber, elements, handleCardErrors, stripe, stripeElementStyles, stripeKey;
    $form = $(form);
    $stripeCardNumberInput = $form.find('.stripe-element-card-number');
    $stripeExpirationInput = $form.find('.stripe-element-card-expiry');
    $stripeCvcInput = $form.find('.stripe-element-card-cvc');
    $stripeMessages = $form.find('.stripe-element-messages');
    $stripeTokenInput = $form.find('.stripe-token');
    $tos = $form.find('.terms-of-service');
    $submitBtn = $form.find('button.submit');
    stripeKey = Meta('key-stripe-publishable');
    stripe = Stripe(stripeKey);
    elements = stripe.elements();
    stripeElementStyles = {
      base: {
        fontSize: '16px'
      }
    };
    cardNumber = elements.create('cardNumber', {
      style: stripeElementStyles
    });
    cardExpiry = elements.create('cardExpiry', {
      style: stripeElementStyles
    });
    cardCvc = elements.create('cardCvc', {
      style: stripeElementStyles
    });
    cardNumber.mount($stripeCardNumberInput.get(0));
    cardExpiry.mount($stripeExpirationInput.get(0));
    cardCvc.mount($stripeCvcInput.get(0));
    handleCardErrors = function(e, el) {
      var $flash;
      if (e.error) {
        $flash = $('<div />').addClass('flash error size-inline').text(e.error.message);
        $stripeMessages.html($flash);
        return $stripeMessages.addClass('with-errors');
      } else {
        $stripeMessages.removeClass('with-errors');
        return $stripeMessages.empty();
      }
    };
    cardNumber.addEventListener('change', function(e) {
      return handleCardErrors(e, cardNumber);
    });
    cardExpiry.addEventListener('change', function(e) {
      return handleCardErrors(e, cardExpiry);
    });
    cardCvc.addEventListener('change', function(e) {
      return handleCardErrors(e, cardCvc);
    });
    $form.on('submit', function(e) {
      e.preventDefault();
      if (!$tos.prop('checked')) {
        return false;
      }
      $stripeMessages.removeClass('with-errors');
      return stripe.createToken(cardNumber, prepAddressAttrs($stripeCardNumberInput)).then(function(result) {
        if (result.token) {
          $stripeTokenInput.val(result.token.id);
          $form.unbind('submit');
          return form.submit();
        } else {
          return alert(result.error.message);
        }
      });
    });
    $tos.on('change', function(e) {
      if ($tos.prop('checked')) {
        return $submitBtn.prop('disabled', false);
      } else {
        return $submitBtn.prop('disabled', true);
      }
    });
    return $tos.trigger('change');
  });
  return $('form.billing-address-config').each(function(index, form) {
    var $form, $toggle;
    $form = $(form);
    $toggle = $form.find('input.address-config-toggle:first');
    $form.on('change', function(e) {
      if ($toggle.prop('checked')) {
        return $form.removeClass('billing-address-required');
      } else {
        return $form.addClass('billing-address-required');
      }
    });
    return $form.trigger('change');
  });
};
