var FontWhisperer, ImageCover, ImageLoad, Modal, fill_marquee, scroll_marquee;

FontWhisperer = require('util/font-whisperer');

ImageCover = require('util/image-cover');

ImageLoad = require('util/image-load');

Modal = require('util/modal');

fill_marquee = function($marquee, html) {
  var $textNode, marqueeWidth, results, textWidth;
  $marquee.empty();
  marqueeWidth = $marquee.width();
  textWidth = 0;
  results = [];
  while (textWidth < marqueeWidth * 3) {
    $textNode = $('<h1/>').addClass('marquee-segment').html(html);
    $marquee.append($textNode);
    results.push(textWidth = textWidth + $textNode.outerWidth());
  }
  return results;
};

scroll_marquee = function($marquee) {
  var $intervalBasis, interval;
  $intervalBasis = $marquee.find(':first-child');
  interval = $intervalBasis.outerWidth();
  return $marquee.animate({
    left: -interval
  }, {
    duration: 10000,
    easing: 'linear',
    complete: function() {
      $marquee.css({
        left: 0
      });
      return scroll_marquee($marquee);
    }
  });
};

module.exports = function() {
  new FontWhisperer($('html'), function() {
    return $('.marquee').each(function(index, m) {
      var $marquee, $segment, html;
      $marquee = $(m);
      $segment = $marquee.find('h1');
      html = $segment.html();
      fill_marquee($marquee, html);
      $marquee.addClass('ready');
      return scroll_marquee($marquee);
    });
  });
  $('.product-detail .additional-images .double figure').each(function(index, img) {
    var $img, cover;
    $img = $(img);
    cover = new ImageCover($img, {
      min: 640
    });
    return new ImageLoad($img, function(i) {
      return cover.resize();
    });
  });
  $('.summon-size-chart').each(function(index, el) {
    var $chart, $el;
    $el = $(el);
    $chart = $el.find('.size-chart-table');
    return $el.on('click', function(e) {
      e.preventDefault();
      return new Modal($chart);
    });
  });
  return $('.product-image').each(function(index, tile) {
    var $img, $tile, cover;
    $tile = $(tile);
    $img = $tile.find('img');
    cover = new ImageCover($img, {
      mode: $tile.data('thumbnail-sizing')
    });
    return new ImageLoad($img, function(i) {
      return cover.resize();
    });
  });
};
