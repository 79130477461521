var $, Sizer;

$ = require('jquery');

Sizer = require('util/sizer');

module.exports = function() {
  var body, getScroll, header, html, lockScroll, mobile_featured, mobile_nav, mobile_nav_button, mobile_nav_open, unlockScroll;
  html = $(document.documentElement);
  body = $(document.body);
  header = $('header');
  mobile_nav_open = false;
  mobile_nav = $('aside.mobile-menu');
  mobile_nav_button = $('.mobile-nav-toggle');
  mobile_featured = $('.featured-mobile');
  mobile_nav_button.on('click', function(e) {
    if (mobile_nav_open === true) {
      return unlockScroll();
    } else {
      return lockScroll();
    }
  });
  getScroll = function() {
    var pos;
    pos = window.scrollY || document.documentElement.scrollTop;
    console.log(pos);
    return pos;
  };
  lockScroll = function() {
    this.currentScrolledY = getScroll();
    console.log('this.currentScrolledY: ', this.currentScrolledY);
    body.css('top', -this.currentScrolledY + 'px');
    body.addClass('mobile-menu-open');
    if (this.currentScrolledY > 0) {
      header.addClass('black');
      if (mobile_featured) {
        window.setTimeout(function() {
          if (this.currentScrolledY > 0) {
            return mobile_featured.addClass('hide');
          }
        }, 150);
      }
    }
    mobile_nav_button.addClass('active');
    mobile_nav.addClass('open');
    return mobile_nav_open = true;
  };
  unlockScroll = function() {
    body.css('top', '0');
    body.removeClass('mobile-menu-open');
    console.log('this.currentScrolledY: ', this.currentScrolledY);
    if (mobile_featured) {
      window.setTimeout(function() {
        if (this.currentScrolledY > 0) {
          html.removeClass('featured-open');
          body.removeClass('featured-open');
          mobile_featured.addClass('hide');
          return window.scrollTo(0, this.currentScrolledY);
        }
      }, 20);
    } else {
      if (this.currentScrolledY > 0) {
        window.scrollTo(0, this.currentScrolledY);
      }
    }
    mobile_nav_button.removeClass('active');
    mobile_nav.removeClass('open');
    return mobile_nav_open = false;
  };
  return new Sizer({
    max: 1024,
    onExit: function(e) {
      mobile_nav.removeClass('open');
      mobile_nav_button.removeClass('active');
      return html.removeClass('mobile-menu-open');
    }
  });
};
