var $, ImageLoad;

$ = require('jquery');

ImageLoad = require('util/image-load');

module.exports = function() {
  var explore_button, explore_content, explore_is_visible, hide_section, show_section;
  $('section.header-image img').each(function(index, el) {
    return new ImageLoad($(el), function() {});
  });
  explore_is_visible = false;
  explore_button = $('.explore-button');
  explore_content = $('.explore-wrapper');
  show_section = function(section) {
    if (section === "explore") {
      explore_content.addClass('active');
      explore_button.addClass('active');
      return explore_is_visible = true;
    }
  };
  hide_section = function(section) {
    if (section === "explore") {
      explore_content.removeClass('active');
      explore_button.removeClass('active');
      return explore_is_visible = false;
    }
  };
  return explore_button.on('click', function(e) {
    var section;
    e.preventDefault();
    section = "explore";
    if (explore_is_visible) {
      return hide_section(section);
    } else {
      return show_section(section);
    }
  });
};
